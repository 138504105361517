<template>
  <sub-page
      v-model="$store.state.newsletter.pageData"
  >

    <v-sheet v-if="!$store.state.business.selectedBusiness.has_one_payment"
             :class="[{'d-flex' : !SM}]"
             class="pa-6 justify-space-between align-center wsRoundedHalf mt-6"
             :color="wsLIGHTCARD"
    >

      <div class="align-center"   :class="[{'d-flex' : !SM} , { 'mt-3' : SM }]">
        <v-icon large class="mr-2" :color="wsDARK">mdi-information-outline</v-icon>
        <h5 style="max-width: 600px"  :class="[{'mt-3' : SM}]">
          {{  $t('NewsletterSecurityMessage')  }}
        </h5>
      </div>

      <ws-button
          @click="$router.push(businessDashLink('plans_payments'))"
          :class="[{'mt-3' : SM}]"
          :block="SM"
          label="GoToPayments"
          icon="mdi-chevron-right"
      />
    </v-sheet>

    <div class="d-flex flex-wrap mt-8">
      <v-hover #default="{hover}">
        <v-sheet @click="$router.push(businessDashLink('newsletter/email'))" :dark="hover" :width="!SM ? '300' : '100%'" :color="hover ? wsACCENT : wsLIGHTACCENT" class="pa-10 wsRoundedHalf mr-3 pointer">
          <v-icon :color="hover ? 'white' : wsACCENT">mdi-mail</v-icon>
          <h3>{{  $t('Email')  }}</h3>
        </v-sheet>
      </v-hover>
    <v-hover #default="{hover}">
      <v-sheet @click="$router.push(businessDashLink('newsletter/telegram'))" :dark="hover"  :width="!SM ? '300' : '100%'"  :color="hover ? wsACCENT :  wsLIGHTACCENT" :class="SM ? 'mt-3' : null" class="pa-10 wsRoundedHalf pointer">
        <v-icon :color="hover ? 'white' : wsACCENT">mdi-send</v-icon>
        <h3>Telegram</h3>
      </v-sheet>
    </v-hover>


    </div>

  </sub-page>
</template>

<script>
export default {
  name: "newslettersDashboard"
}
</script>

<style scoped>

</style>